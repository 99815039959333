import React from 'react';

const IconMath = () => {
  return (
    <svg
      className="icon icon-math"
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4459 24.3568C24.8625 23.9402 25.5379 23.9402 25.9544 24.3568L28.4002 26.8025L30.8459 24.3568C31.2625 23.9402 31.9379 23.9402 32.3544 24.3568C32.771 24.7733 32.771 25.4487 32.3544 25.8653L29.9087 28.311L32.3544 30.7568C32.771 31.1733 32.771 31.8487 32.3544 32.2653C31.9379 32.6818 31.2625 32.6818 30.8459 32.2653L28.4002 29.8195L25.9544 32.2653C25.5379 32.6818 24.8625 32.6818 24.4459 32.2653C24.0294 31.8487 24.0294 31.1733 24.4459 30.7568L26.8917 28.311L24.4459 25.8653C24.0294 25.4487 24.0294 24.7733 24.4459 24.3568Z"
        fill="#7E5CE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1335 16.5777C24.1335 15.9886 24.6111 15.511 25.2002 15.511H31.0668C31.6559 15.511 32.1335 15.9886 32.1335 16.5777C32.1335 17.1668 31.6559 17.6444 31.0668 17.6444H25.2002C24.6111 17.6444 24.1335 17.1668 24.1335 16.5777Z"
        fill="#7E5CE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3335 28.311C11.3335 27.7219 11.8429 27.2444 12.4713 27.2444H18.7291C19.3574 27.2444 19.8668 27.7219 19.8668 28.311C19.8668 28.9001 19.3574 29.3777 18.7291 29.3777H12.4713C11.8429 29.3777 11.3335 28.9001 11.3335 28.311Z"
        fill="#7E5CE7"
      />
      <path
        d="M16.6668 25.111C16.6668 25.7001 16.1893 26.1777 15.6002 26.1777C15.0111 26.1777 14.5335 25.7001 14.5335 25.111C14.5335 24.5219 15.0111 24.0444 15.6002 24.0444C16.1893 24.0444 16.6668 24.5219 16.6668 25.111Z"
        fill="#7E5CE7"
      />
      <path
        d="M16.6668 31.511C16.6668 32.1001 16.1893 32.5777 15.6002 32.5777C15.0111 32.5777 14.5335 32.1001 14.5335 31.511C14.5335 30.9219 15.0111 30.4444 15.6002 30.4444C16.1893 30.4444 16.6668 30.9219 16.6668 31.511Z"
        fill="#7E5CE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6002 12.311C16.1893 12.311 16.6668 12.7886 16.6668 13.3777V15.511H18.8002C19.3893 15.511 19.8668 15.9886 19.8668 16.5777C19.8668 17.1668 19.3893 17.6444 18.8002 17.6444H16.6668V19.7777C16.6668 20.3668 16.1893 20.8444 15.6002 20.8444C15.0111 20.8444 14.5335 20.3668 14.5335 19.7777V17.6444H12.4002C11.8111 17.6444 11.3335 17.1668 11.3335 16.5777C11.3335 15.9886 11.8111 15.511 12.4002 15.511H14.5335V13.3777C14.5335 12.7886 15.0111 12.311 15.6002 12.311Z"
        fill="#7E5CE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1111 7.77778C10.1656 7.77778 7.77778 10.1656 7.77778 13.1111V31.7778C7.77778 34.7233 10.1656 37.1111 13.1111 37.1111H30.8889C33.8344 37.1111 36.2222 34.7233 36.2222 31.7778V13.1111C36.2222 10.1656 33.8344 7.77778 30.8889 7.77778H29.5556C29.0646 7.77778 28.6667 7.37981 28.6667 6.88889C28.6667 6.39797 29.0646 6 29.5556 6H30.8889C34.8162 6 38 9.18375 38 13.1111V31.7778C38 35.7051 34.8162 38.8889 30.8889 38.8889H13.1111C9.18375 38.8889 6 35.7051 6 31.7778V13.1111C6 9.18375 9.18375 6 13.1111 6H24.6667C25.1576 6 25.5556 6.39797 25.5556 6.88889C25.5556 7.37981 25.1576 7.77778 24.6667 7.77778H13.1111Z"
        fill="#3E209B"
      />
      <defs>
        <linearGradient
          id="SVGgold"
          x1="22.9799"
          y1="3"
          x2="22.9799"
          y2="41"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FCEA59" />
          <stop offset="1" stopColor="#FFB60A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconMath;
