import React from 'react';

const IconPhys = () => {
  return (
    <svg
      className="icon icon-phys"
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9627 9.63452C17.7513 12.7497 16.9804 17.1212 16.9804 22C16.9804 26.8788 17.7513 31.2503 18.9627 34.3655C19.5697 35.9262 20.2677 37.1211 20.9919 37.9086C21.7152 38.6954 22.3883 39 22.9804 39C23.5726 39 24.2457 38.6954 24.969 37.9086C25.6931 37.1211 26.3912 35.9262 26.9982 34.3655C28.2096 31.2503 28.9804 26.8788 28.9804 22C28.9804 17.1212 28.2096 12.7497 26.9982 9.63452C26.3912 8.07377 25.6931 6.87893 24.969 6.09136C24.2457 5.30464 23.5726 5 22.9804 5C22.3883 5 21.7152 5.30464 20.9919 6.09136C20.2677 6.87893 19.5697 8.07377 18.9627 9.63452ZM19.5196 4.7377C20.4712 3.70272 21.6396 3 22.9804 3C24.3213 3 25.4897 3.70272 26.4413 4.7377C27.3921 5.77182 28.2024 7.21303 28.8622 8.90963C30.1842 12.3092 30.9804 16.9377 30.9804 22C30.9804 27.0623 30.1842 31.6908 28.8622 35.0904C28.2024 36.787 27.3921 38.2282 26.4413 39.2623C25.4897 40.2973 24.3213 41 22.9804 41C21.6396 41 20.4712 40.2973 19.5196 39.2623C18.5688 38.2282 17.7585 36.787 17.0987 35.0904C15.7766 31.6908 14.9804 27.0623 14.9804 22C14.9804 16.9377 15.7766 12.3092 17.0987 8.90963C17.7585 7.21303 18.5688 5.77182 19.5196 4.7377Z"
        fill="#3E209B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.2576 13.5C7.92931 14.0686 7.87604 14.9164 8.32707 16.1104C8.52222 16.6271 8.26159 17.2041 7.74493 17.3993C7.22827 17.5944 6.65124 17.3338 6.45609 16.8172C5.89281 15.3259 5.78033 13.7907 6.52555 12.5C7.19599 11.3387 8.38875 10.6782 9.76087 10.3716C11.1319 10.0653 12.7851 10.0841 14.5843 10.361C18.1895 10.9159 22.5959 12.5406 26.98 15.0718C31.3641 17.6029 34.9744 20.6067 37.2575 23.4514C38.3969 24.8711 39.2399 26.2934 39.6601 27.6339C40.0806 28.9755 40.105 30.3387 39.4345 31.5C38.7641 32.6612 37.5713 33.3217 36.1992 33.6283C34.8282 33.9347 33.1749 33.9158 31.3757 33.6389C27.7706 33.084 23.3641 31.4593 18.98 28.9282C14.6371 26.4207 11.054 23.4499 8.76804 20.6297C8.42027 20.2007 8.48615 19.5709 8.91519 19.2232C9.34423 18.8754 9.97396 18.9413 10.3217 19.3703C12.4188 21.9575 15.7952 24.78 19.98 27.1961C24.2052 29.6355 28.3765 31.1537 31.68 31.6622C33.3351 31.9169 34.7189 31.9098 35.763 31.6764C36.806 31.4434 37.4064 31.0128 37.7025 30.5C37.9985 29.9872 38.0713 29.2519 37.7516 28.2321C37.4316 27.2112 36.7459 26.0092 35.6977 24.7032C33.6056 22.0965 30.2052 19.2432 25.98 16.8038C21.7549 14.3644 17.5836 12.8462 14.2801 12.3377C12.625 12.083 11.2412 12.0902 10.197 12.3235C9.15405 12.5565 8.55367 12.9872 8.2576 13.5Z"
        fill="#3E209B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.6976 19.2967C33.6055 21.9034 30.205 24.7567 25.9799 27.1961C21.7547 29.6355 17.5835 31.1537 14.2799 31.6622C12.6248 31.9169 11.241 31.9098 10.1969 31.6764C9.15389 31.4434 8.55351 31.0128 8.25745 30.5C7.96138 29.9872 7.88865 29.2519 8.2083 28.2321C8.5283 27.2112 9.21402 26.0092 10.2622 24.7032C12.3543 22.0965 15.7547 19.2432 19.9799 16.8038C24.205 14.3644 28.3763 12.8462 31.6798 12.3377C33.335 12.083 34.7188 12.0902 35.7629 12.3235C36.8059 12.5565 37.4062 12.9872 37.7023 13.5C37.9984 14.0128 38.0711 14.748 37.7515 15.7678C37.4315 16.7887 36.7457 17.9907 35.6976 19.2967ZM39.6599 16.366C40.0804 15.0244 40.1048 13.6612 39.4344 12.5C38.7639 11.3387 37.5712 10.6782 36.199 10.3716C34.8281 10.0653 33.1748 10.0841 31.3756 10.361C27.7705 10.9159 23.364 12.5406 18.9799 15.0718C14.5958 17.6029 10.9855 20.6067 8.70241 23.4514C7.563 24.8711 6.72003 26.2934 6.29986 27.6339C5.87934 28.9755 5.85496 30.3387 6.5254 31.5C7.19583 32.6612 8.3886 33.3217 9.76071 33.6283C11.1317 33.9347 12.785 33.9158 14.5842 33.6389C18.1893 33.084 22.5958 31.4593 26.9799 28.9282C31.364 26.397 34.9743 23.3932 37.2573 20.5485C38.3968 19.1288 39.2397 17.7065 39.6599 16.366Z"
        fill="#3E209B"
      />
      <circle cx="22.9805" cy="22" r="3" fill="#7E5CE7" />
      <defs>
        <linearGradient
          id="SVGgold"
          x1="22.9799"
          y1="3"
          x2="22.9799"
          y2="41"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FCEA59" />
          <stop offset="1" stopColor="#FFB60A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconPhys;
