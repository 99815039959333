import React, { useState } from 'react';
import Icon from '../../icons/FlatIcon';
import './index.scss';
import { $class } from '../../utils';

const Input = ({ value, onChange, label, name, type = 'text', invalid }) => {
  const [hidden, setHidden] = useState(type === 'password');
  return (
    <div className={$class(['input', ['invalid', invalid]])}>
      <input
        onChange={onChange}
        value={value}
        required
        type={hidden ? type : 'text'}
        name={name}
        id={`input-${name}`}
      />
      <label htmlFor={`input-${name}`}>{label}</label>
      {type === 'password' && (
        <button className="input-hide" onClick={() => setHidden((x) => !x)}>
          <Icon slug={`fi-rr-eye${hidden ? '-crossed' : ''}`} />
        </button>
      )}
    </div>
  );
};

export default Input;
