import moment from 'moment';
import React from 'react';
import { useApp } from '../../hooks/useApp';
import useGetApi from '../../hooks/useGetApi';
import { getSubjectIcon } from '../../icons';
import { useParams } from 'react-router';

const getParams = (e) => {
  if (e.page === 'lesson' || e.page === 'practice') {
    const match = e.path.match(/\/subjects\/(.+)\/(\d+)_/);
    if (match) {
      return { subject: match[1], themeId: match[2] };
    }
  }

  return {};
};

const Activity = () => {
  const { userId } = useParams();
  const { subjects } = useApp();
  const [loading, activity] = useGetApi(`user/${userId}/activity`, []);

  const getSubjectColor = (e) => {
    const { subject } = getParams(e);
    return subjects[subject]?.color;
  };

  const getTheme = (slug, id) => {
    return subjects[slug].themes[id] || {};
  };

  const getTitle = (e) => {
    const params = getParams(e);
    switch (e.page) {
      case 'lesson':
      case 'practice':
        const theme = getTheme(params.subject, params.themeId);
        return theme.label || theme.label_kz;

      case 'dashboard':
        return 'Главная';

      case 'mytests':
        return 'Мои тесты';

      case 'test':
        return 'Тест ЕНТ';

      case 'leaderboard':
        return 'Лидерборд';

      case 'profile':
        return 'Профиль';

      case 'statistic':
        return 'Статистика';

      default:
        return e.page;
    }
  };

  return (
    <div className="user-details-item user-details-activity main-block">
      <h3>Активность</h3>
      <div className="activity-list main-scroll">
        {activity.map((row) => (
          <div key={row.date} className="activity__item">
            <div className="activity__item-date">
              <h3>{moment(row.date).format('DD')}</h3>
              <h6>{moment(row.date).format('DD MMMM').slice(3)}</h6>
            </div>
            <div className="activity__item-timeline">
              <div className="start-date activity__item-timeline-date">
                {moment(row.start).format('HH:mm')}
              </div>
              <div className="end-date activity__item-timeline-date">
                {moment(row.end).format('HH:mm')}
              </div>
              {row.timeline?.map((x, i, arr) => (
                <div
                  key={i}
                  onClick={() => window.open(x.path)}
                  className={`${
                    x.path === null ? 'activity__item-eventnull' : 'activity__item-event'
                  }${x.path !== null ? ` activity__item-event--${x.page}` : ''}`}
                  style={{
                    width: x.path ? `${(x.duration / row.durationAll) * 100}%` : '5%',
                    borderTopLeftRadius: arr[i - 1]?.path ? 0 : '15px',
                    borderBottomLeftRadius: arr[i - 1]?.path ? 0 : '15px',
                    borderTopRightRadius: arr[i + 1]?.path ? 0 : '15px',
                    borderBottomRightRadius: arr[i + 1]?.path ? 0 : '15px',
                    borderRight: arr[i + 1]?.path && x.path !== null ? '2px solid white' : 'none',
                    ...(['lesson', 'practice'].includes(x.page)
                      ? { background: getSubjectColor(x) }
                      : {}),
                  }}>
                  {x.path !== null && (
                    <div className="activity__item-details">
                      <div className='activity__item-details-title'>
                        <span>{getTitle(x)}</span>
                      </div>
                      <div className="duration">{moment.duration(x.duration).humanize()}</div>
                      <div className="label">{x.page === 'practice' ? 'Практика' : ''}</div>
                    </div>
                  )}
                  {x.path === null && (
                    <div className="activity__item-duration">
                      {moment.duration(x.duration).humanize()}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Activity;
