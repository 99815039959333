import { Navigate, Route, Routes } from 'react-router-dom';
import Auth from './components/Auth/Auth';
import Dashboard from './components/Dashboard/Dashboard';
import Header from './components/Header/Header';
import { AppProvider } from './contexts/AppContext';
import { useAuth } from './hooks/useAuth';
import UserDetails from './components/UserDetails';

// ----------------------------------------------------------------------

export default function Router({ loading }) {
  const { token } = useAuth();
  const isAuth = !!token;

  if (isAuth) {
    // auth users
    return (
      <AppProvider>
        <div className="app">
          <Header />
          <div className="app-wrapper">
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/students/:userId" element={<UserDetails />} />

              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>
          </div>
        </div>
      </AppProvider>
    );
  }

  return (
    <Routes>
      <Route path="/auth" element={<Auth />} />
      <Route path="*" element={<Navigate to="/auth" />} />
    </Routes>
  );
}
