import { createContext, useCallback, useState } from 'react';
import Router from '../routes';

export const AuthContext = createContext({
  token: null,
  login: () => {},
  logout: () => {
    console.warn('Logout');
    localStorage.removeItem(storageUser);
  },
});

const storageUser = 'userToken';

export const AuthProvider = () => {
  const localToken = localStorage.getItem(storageUser);
  const [token, setToken] = useState(localToken || null);

  const login = useCallback((jwtToken, save = true) => {
    setToken(jwtToken);
    if (save) localStorage.setItem(storageUser, jwtToken);
  }, []);

  const logout = useCallback(() => {
    console.warn('Logout');
    setToken(null);
    localStorage.removeItem(storageUser);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        login,
        logout,
      }}>
      <Router />
    </AuthContext.Provider>
  );
};
