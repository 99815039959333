const $class = (classes = []) => {
  return classes
    .filter((x) => x)
    .map((x) => (Array.isArray(x) ? (x[1] ? x[0] : null) : x))
    .join(' ');
};

/**
 *
 * @param {number} min
 * @param {number} max
 *
 */
const getRandomString = (min, max) => {
  return Array(getRandomInt(min, max)).fill('_').join('');
};
/**
 *
 * @param {number} min
 * @param {number} max
 *
 */
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const avg = (arr, field) => {
  if (!arr || arr.length === 0) return 0;
  const sum = arr.reduce((prev, curr) => curr + (field ? prev[field] : prev), 0);
  return sum / arr.length;
};

const emailRegex = (email) => {
  return (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )?.length > 0
  );
};

const getPercentColor = (percent) => {
  if (percent < 1) percent = percent.toFixed(2) * 100;
  if (0 <= percent && percent <= 33) return '#F6747A';
  if (34 <= percent && percent <= 66) return '#F7986F';
  if (67 <= percent && percent <= 100) return '#6FCF97';
};

/**
 *
 * @param {number} amount
 * @param {string[]} cases For slavic languages: ['яблоко', 'яблока', 'яблок']
 * @returns {string}
 */
function pluralize(amount, cases, show_amount = true) {
  if (cases.length === 1) return `${amount} ${cases[0]}`;
  const indexes = [2, 0, 1, 1, 1, 2];
  const mod100 = amount % 100;
  const mod10 = amount % 10;
  const index = mod100 > 4 && mod100 < 20 ? 2 : indexes[mod10 < 5 ? mod10 : 5];
  return `${show_amount ? amount + ' ' : ''}${cases[index]}`;
}

const formatPhone = (str) => {
  str = `${str}`
  if (!str) return '';
  const digitsOnly = str.replace(/\D/g, '');

  const operatorCode = digitsOnly.slice(1, 4);
  const phoneNumber = digitsOnly.slice(4);

  const formattedNumber = `+7 (${operatorCode}) ${phoneNumber.slice(
    0,
    3
  )} ${phoneNumber.slice(3, 5)}-${phoneNumber.slice(5, 7)}`;
  return formattedNumber;
};

export { $class, avg, emailRegex, getPercentColor, pluralize, formatPhone, getRandomString, getRandomInt };
