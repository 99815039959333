import React from 'react';

const IconLiter = () => {
  return (
    <svg
      className="icon icon-liter"
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99934 7.16053C10.6108 6.41814 11.529 6.00146 12.6221 6.00146H33.9994C34.5045 6.00146 34.9302 6.37802 34.9919 6.87926C35.0537 7.38049 34.732 7.84908 34.242 7.97159C33.2974 8.2078 32.8434 8.52892 32.6214 8.78787C32.4128 9.03127 32.3277 9.3144 32.3277 9.67364C32.3277 10.2259 31.88 10.6736 31.3277 10.6736C30.7755 10.6736 30.3277 10.2259 30.3277 9.67364C30.3277 9.13531 30.4326 8.55318 30.742 8.00146H12.6221C12.0611 8.00146 11.7349 8.19919 11.5431 8.43204C11.3362 8.68329 11.2015 9.08095 11.2411 9.597C11.2431 9.6225 11.2441 9.64806 11.2441 9.67364C11.2441 10.193 11.3583 10.477 11.475 10.6426C11.591 10.8072 11.7785 10.9531 12.1058 11.0708C12.8336 11.3326 13.8937 11.3458 15.2947 11.3458H33.9994C34.5517 11.3458 34.9994 11.7935 34.9994 12.3458L34.9997 28.3788C34.9998 28.9311 34.552 29.3788 33.9998 29.3788C33.4475 29.3788 32.9998 28.9311 32.9997 28.3789L32.9995 13.3458H15.2947C15.2561 13.3458 15.2172 13.3458 15.1782 13.3458C13.9348 13.3462 12.524 13.3467 11.4289 12.9528C11.3669 12.9305 11.3053 12.9067 11.2441 12.8814V34.792C11.2441 35.7754 11.4295 36.1782 11.7278 36.4192C12.1018 36.7214 12.92 36.9987 14.7603 36.9987H32.9999L32.9998 33.1888C32.9998 32.6365 33.4475 32.1888 33.9998 32.1887C34.5521 32.1887 34.9998 32.6364 34.9998 33.1887L34.9999 37.9986C34.9999 38.2639 34.8946 38.5182 34.707 38.7057C34.5195 38.8933 34.2651 38.9986 33.9999 38.9986H14.7603C12.8596 38.9986 11.4196 38.7415 10.4708 37.9748C9.44621 37.1469 9.24408 35.9464 9.24408 34.792V9.7095C9.18248 8.79549 9.41177 7.87392 9.99934 7.16053Z"
        fill="#3E209B"
      />
      <path
        d="M15.5791 18.7591C15.5791 18.1687 16.0577 17.6902 16.648 17.6902H27.3367C27.927 17.6902 28.4055 18.1687 28.4055 18.7591V23.0345C28.4055 23.6248 27.927 24.1034 27.3367 24.1034H16.648C16.0577 24.1034 15.5791 23.6248 15.5791 23.0345V18.7591Z"
        fill="#7E5CE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5791 28.3789C15.5791 27.7886 16.0577 27.31 16.648 27.31H26.2678C26.8581 27.31 27.3367 27.7886 27.3367 28.3789C27.3367 28.9692 26.8581 29.4477 26.2678 29.4477H16.648C16.0577 29.4477 15.5791 28.9692 15.5791 28.3789ZM15.5791 31.5855C15.5791 30.9952 16.0577 30.5166 16.648 30.5166H22.2595C22.8498 30.5166 23.3284 30.9952 23.3284 31.5855C23.3284 32.1758 22.8498 32.6543 22.2595 32.6543H16.648C16.0577 32.6543 15.5791 32.1758 15.5791 31.5855Z"
        fill="#7E5CE7"
      />
    </svg>
  );
};

export default IconLiter;
