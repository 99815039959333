import React from 'react';
import { $class } from '../utils';

const Icon = ({ slug, className = '', loading, onClick }) => {
  if (onClick)
    return (
      <button
        onClick={onClick}
        className={$class(['icon', 'icon--btn', className])}>
        <i className={$class(['fi', slug, ['icon--loading', loading]])}></i>
      </button>
    );
  return <i className={$class(['icon', 'fi', slug, className, ['icon--loading', loading]])}></i>;
};

export default Icon;
