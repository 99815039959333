import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useApp } from '../../hooks/useApp';
import useGetApi from '../../hooks/useGetApi';
import Select from '../../ui/Select/Select';
import { getPercentColor, getRandomInt, getRandomString } from '../../utils';

const localName = 'statTheme-subject';
const mockup_themes = Array(10)
  .fill({})
  .map((v, i) => ({ label: getRandomString(20, 80), progress: getRandomInt(0, 100) }));

const filters = [
  {
    label: 'Лучшие',
    type: 'best',
    sort: (themes) => {
      console.log('best ======== ', themes);
      return [...themes].sort((a, b) => b.progress - a.progress);
    },
  },
  {
    label: 'Плохие',
    type: 'worst',
    sort: (themes) => {
      return [...themes].sort((a, b) => a.progress - b.progress);
    },
  },
];

const Statistic = ({ user }) => {
  const { userId } = useParams();
  const { subjects } = useApp();
  const [subject, setSubject] = useState({});

  let [loading, stat] = useGetApi(subject ? `user/${userId}/themes_stat` : null, []);

  useEffect(() => {
    const localSubject = subjects[localStorage.getItem(localName)] || subjects[0];
    if (localSubject) setSubject(localSubject);
  }, []);

  const [filter, setFilter] = useState({
    sort: (t) => t,
  });

  const changeFilter = (f) => {
    if (f.type === filter.type) {
      return setFilter({
        sort: (t) => t,
      });
    }

    setFilter(f);
  };

  const changeSubject = (v) => {
    localStorage.setItem(localName, v.slug);
    setSubject(v);
  };

  const filteredThemes = () => {
    if (loading) return mockup_themes;
    if (!subject.themes) return [];

    return filter.sort(
      Object.values(subject.themes).filter(x => !x.props.includes('noLesson')).map((x) => ({ ...x, progress: stat[x.id] || 0 }))
    );
  };

  return (
    <div className="user-details-item user-details-statistic">
      <h2 className="title">Анализ тем</h2>

      <div className="user-details-statistic-wrap main-block">
        <div className="top">
          <Select
            className="top-select"
            value={loading ? { label: 'Загрузка' } : subject}
            options={Object.keys(subjects)
              .filter((x) => user.role?.access?.subjects?.includes(x))
              .map((key) => ({ ...subjects[key], value: key }))}
            onChange={changeSubject}
          />

          <div className="top-filter">
            {filters.map((f, i) => (
              <button
                key={i}
                className={`${filter.type === f.type ? 'active' : ''}`}
                onClick={() => changeFilter(f)}>
                {f.label}
              </button>
            ))}
          </div>
        </div>

        <div className="themes main-scroll">
          {filteredThemes().map((t, i) => (
            <StatThemeItem
              className={loading ? 'skeleton-inner' : ''}
              key={i}
              link={`/subjects/${subject.slug}/${t.slug}`}
              label={t.label || t.label_kz}
              progress={t.progress}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const StatThemeItem = ({ label, progress, link = '', className = '' }) => {
  const [delayedProgress, setDelayedProgress] = useState(0);

  setTimeout(() => {
    setDelayedProgress(progress);
  }, 100);

  return (
    <div className={`themes__item ${className}`}>
      <h6 className="label">{label}</h6>
      <div className="progress">
        <div className="progress-bar">
          <div className="overlay" style={{ backgroundColor: getPercentColor(progress) }}></div>
          <div
            className="value"
            style={{
              width: delayedProgress + '%',
              backgroundColor: getPercentColor(progress),
            }}></div>
        </div>
        <div className="percent" style={{ color: getPercentColor(progress) }}>
          {progress}%
        </div>
      </div>
    </div>
  );
};

export default Statistic;
