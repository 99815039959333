import React from 'react';
import Icon from '../../icons/FlatIcon';
import { $class } from '../../utils';
import './index.scss'

const IconButton = ({ slug, onClick, className }) => {
    return (
        <button onClick={onClick} className={$class(['icon-button', className])}>
            <Icon slug={slug} />
        </button>
    );
}

export default IconButton;
