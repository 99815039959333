import React from 'react';

const IconHistoryKaz = ({ color = 'black' }) => {
  return (
    <svg
      className="icon icon-historyKaz"
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5933 7.06179C21.5539 7.10136 20.9743 7.16559 20.3051 7.20451C19.6361 7.24344 18.6314 7.31637 18.0724 7.36651C17.5135 7.41674 16.4326 7.49747 15.6704 7.54594C13.1834 7.70423 12.9432 7.76855 12.7107 8.33861C12.5959 8.62026 12.6136 9.27568 12.856 13.7139C12.965 15.7109 12.9648 15.5481 12.8617 16.9113C12.7471 18.425 12.5021 19.6232 12.1225 20.5258C12.026 20.7551 11.8391 21.1982 11.707 21.5105C11.327 22.4091 11.4326 22.5143 12.1574 21.9593C13.5503 20.8927 15.0817 18.9563 17.1022 15.7065C20.0463 10.9715 21.5169 9.19829 23.7825 7.65242C24.5277 7.14381 24.3944 7.04575 22.9163 7.01545C22.2281 7.00136 21.6328 7.02221 21.5933 7.06179Z"
        fill="#3E209B"
      />
      <path
        d="M29.3896 7.08357C29.2626 7.12425 28.9715 7.21202 28.7429 7.27847C27.8083 7.55029 25.8988 8.68032 25.1399 9.41089C25.0637 9.4842 24.7727 9.73156 24.4932 9.96047L24.4579 9.98939C24.1701 10.2251 24.0263 10.3429 23.8957 10.4739C23.7653 10.6048 23.6482 10.7489 23.4139 11.0371L23.385 11.0726C23.1571 11.353 22.8651 11.6866 22.7362 11.814C21.9462 12.5947 20.4843 14.6834 19.2336 16.8186C18.8604 17.4558 18.4205 18.2065 18.256 18.4868C18.0916 18.7672 17.8853 19.1425 17.7976 19.3209C17.0735 20.7946 15.3916 23.3386 14.5845 24.1812C14.0013 24.7901 13.9005 24.684 14.1433 23.7171C14.2449 23.3127 14.3286 22.9088 14.3295 22.8195C14.3311 22.628 14.0335 22.6004 13.8524 22.7752C13.5462 23.0711 12.2602 24.0681 11.4207 24.6605C8.63497 26.6266 8.11207 27.0065 7.30962 27.6477C6.23389 28.5073 5 29.7598 5 29.9923C5 30.2462 5.31854 30.2162 6.99736 29.8042C7.80425 29.6061 9.02567 29.3102 9.71162 29.1466C10.803 28.8861 12.046 28.4964 13.3884 27.9938C15.3433 27.2619 17.5572 25.6025 18.8933 23.8678C19.6208 22.9232 19.7374 22.7237 21.714 19.0429C24.221 14.3743 27.5629 11.3784 30.8216 10.8781C32.0934 10.6828 32.1982 10.6831 32.9464 10.8849C33.8175 11.1199 34.335 11.4028 34.7401 11.8656C35.0615 12.2328 35.0703 12.4008 34.7704 12.4452C34.4283 12.4959 34.3589 12.6767 34.5099 13.124C35.1003 14.8728 35.2961 16.4001 35.3594 19.7501C35.4079 22.3201 35.3963 22.3793 34.8403 22.3793C34.5195 22.3793 34.286 22.5942 34.286 22.8894C34.286 23.4582 33.4456 26.2908 33.0076 27.1986C32.7974 27.6342 32.728 27.7047 32.5307 27.6833C31.5436 27.5765 30.7512 27.4677 30.3596 27.3854C29.0451 27.109 28.9318 27.1368 28.9291 27.7375C28.9276 28.0698 30.3834 29.6975 30.6842 29.6998C30.9139 29.7015 31.7392 29.0956 32.2675 28.5373C32.5393 28.25 32.9852 27.8 33.2584 27.5373C34.5364 26.3081 35.8285 23.7494 36.5183 21.0818C36.9298 19.4901 36.9978 18.8362 37 16.4479C37.0027 13.4504 36.7925 12.3857 35.8105 10.4243C34.9286 8.66261 33.9029 7.71897 32.2373 7.13704C31.8036 6.98551 29.8116 6.94807 29.3896 7.08357Z"
        fill="#3E209B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.4682 12.4805C28.413 13.1991 26.1554 15.3427 24.0852 19.4907C23.6487 20.3654 23.2362 21.2422 23.1686 21.4391C22.8335 22.4146 21.0463 25.1609 20.1184 26.1261C19.1869 27.095 19.1899 27.1153 20.3821 27.9142C22.0804 29.0523 24.1866 29.7379 26.2711 29.8314C27.4269 29.8831 27.4495 29.8674 27.4495 29.0132C27.4495 28.7303 27.4975 28.3184 27.5563 28.098C27.766 27.3115 27.6628 27.1987 26.7318 27.1972C25.7854 27.1955 25.5261 27.0893 25.8767 26.8469C26.7784 26.2238 26.8347 26.0715 26.436 25.3331C25.8753 24.2945 26.0538 23.962 26.8697 24.5252C27.5241 24.9768 27.6526 24.9078 28.3629 23.7231C29.8376 21.2637 30.6093 20.7207 32.6396 20.7138L32.6471 20.7138C33.1529 20.7121 33.4285 20.7111 33.5729 20.573C33.7459 20.4074 33.7304 20.0448 33.6962 19.2477L33.6957 19.2377C33.5793 16.5224 33.3033 14.7914 32.7264 13.1579C32.4461 12.3644 32.299 12.285 31.4682 12.4805ZM31.4339 16.8213C32.3043 16.8175 32.4904 16.4767 31.8627 16.0356C31.6292 15.8716 30.9373 15.8538 30.5906 16.003C30.4636 16.0577 30.0482 16.2124 29.6676 16.347C28.8623 16.6315 28.7312 16.8384 29.225 17.0454C29.5016 17.1614 29.6083 17.1563 30.179 17C30.5324 16.9033 31.097 16.8228 31.4339 16.8213Z"
        fill="#7E5CE7"
      />
      <path
        d="M31.4555 22.3776C30.925 22.6143 30.2098 23.3924 29.6648 24.3254C29.1102 25.2748 29.0765 25.5663 29.5051 25.7078C30.6494 26.0857 31.621 26.1921 31.9047 25.9706C32.1577 25.7731 32.8078 23.5178 32.8078 22.8374C32.8078 22.1964 32.2708 22.0138 31.4555 22.3776Z"
        fill="#7E5CE7"
      />
      <path
        d="M16.797 28.9519C16.0057 29.4391 15.7261 29.5698 14.028 30.2458C13.6637 30.3909 13.6056 30.6179 13.8766 30.8368C15.328 32.0089 15.4748 32.1647 16.9448 34.0932C18.1646 35.6934 18.8961 36.3377 20.3064 37.054C21.2408 37.5285 23.3133 38.1398 23.417 37.9714C23.4429 37.9296 23.2382 37.5473 22.9623 37.1219C22.6864 36.6964 22.4607 36.296 22.4607 36.2321C22.4607 36.1682 22.3785 35.9341 22.278 35.7119C22.057 35.2235 22.0174 34.2421 22.2186 34.2421C22.2887 34.2421 22.5138 34.4054 22.7189 34.605C23.5639 35.4277 24.996 36.4044 25.7906 36.6999C26.0171 36.7841 26.3686 36.9162 26.5719 36.9934C27.7186 37.4291 29.0497 37.5912 29.198 37.3132C29.3302 37.0655 29.3282 33.9156 29.1955 33.3398C28.8845 31.9906 28.7321 31.7679 28.2012 31.887C27.5208 32.0399 25.2344 31.9313 24.0775 31.6911C21.5807 31.1729 19.8647 30.3499 18.4025 28.9695C17.7908 28.3918 17.7082 28.3909 16.797 28.9519Z"
        fill="#3E209B"
      />
    </svg>
  );
};

export default IconHistoryKaz;
