import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import 'moment/locale/ru';

import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import './scss/app.scss';

const App = () => {
  return (
    <BrowserRouter>
      <ReactNotifications />
      <AuthProvider />
    </BrowserRouter>
  );
};

export default App;
