import React from 'react';

const IconInfo = () => {
  return (
    <svg className='icon icon-info' width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2539 13.1377C18.034 13.2912 18.0156 13.7076 18.0057 18.7431C17.993 25.2946 17.882 24.928 19.6369 24.1254C20.867 23.5627 20.7836 23.5524 21.125 24.31C21.528 25.204 21.6825 25.2119 23.58 24.4346C25.5741 23.6177 25.5362 23.6862 24.8656 22.1171C24.7981 21.9594 24.9961 21.8306 25.7496 21.541C27.6487 20.8113 27.6972 20.9098 23.6858 17.3463C18.3011 12.5632 18.6688 12.8482 18.2539 13.1377ZM25.3283 20.4363C25.3292 20.393 24.1147 19.2729 22.6294 17.947C21.1442 16.6212 19.7485 15.3914 19.528 15.2144L19.1272 14.8926L19.1551 18.9608C19.1704 21.1985 19.2303 23.0292 19.2882 23.0292C19.346 23.0294 19.7602 22.8526 20.2088 22.6365C21.2363 22.1415 21.4677 22.2058 21.883 23.1022L22.2056 23.7984L23.0585 23.4292C23.5276 23.2261 23.8977 23.0096 23.881 22.948C23.8642 22.8864 23.7432 22.5601 23.6117 22.2231C23.3086 21.4456 23.401 21.3013 24.4845 20.8589C24.9477 20.6697 25.3273 20.4795 25.3283 20.4363Z"
        fill="#7E5CE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.20469 8.11257C6.83087 8.24538 6.34912 8.70286 6.15937 9.1053L6 9.44327V29.0224L6.15763 29.3566C6.24431 29.5405 6.44287 29.8089 6.59887 29.9532C7.14237 30.4558 6.76363 30.4217 12.1875 30.4568L17.0312 30.4881V35.0655L14.3409 35.0968C11.8846 35.1255 11.6356 35.138 11.4786 35.2413C10.9092 35.6158 10.9291 36.4944 11.5151 36.8529C11.8357 37.0491 32.1643 37.0491 32.4849 36.8529C33.0709 36.4944 33.0908 35.6158 32.5214 35.2413C32.3644 35.138 32.1154 35.1255 29.6591 35.0968L26.9688 35.0655V30.4881L31.8125 30.4568C37.2364 30.4217 36.8576 30.4558 37.4011 29.9532C37.5571 29.8089 37.7557 29.5405 37.8424 29.3566L38 29.0224V18.9531C38 18.4267 37.5733 18 37.0469 18C36.5205 18 36.0938 18.4267 36.0938 18.9531V28.5443H7.90625L7.89019 19.2955C7.88138 14.2087 7.88756 10.0114 7.90394 9.96822C7.92769 9.90558 10.7825 9.89292 22.0137 9.90552L36.0938 9.92132V13.3849C36.0938 13.9113 36.5205 14.338 37.0469 14.338C37.5733 14.338 38 13.9113 38 13.3849V9.44327L37.8406 9.1053C37.6447 8.6897 37.1658 8.24406 36.7698 8.10868C36.3384 7.96127 7.62 7.96503 7.20469 8.11257ZM25.0312 35.0655V30.4881L22.0139 30.4718C19.6268 30.459 18.9902 30.4721 18.9662 30.5349C18.9495 30.5785 18.9432 31.6157 18.9523 32.8398L18.9688 35.0655H25.0312Z"
        fill="#3E209B"
      />
    </svg>
  );
};

export default IconInfo;
