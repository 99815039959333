import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LineElement,
  LinearScale,
  PointElement,
} from 'chart.js';
import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler);

const AimChart = ({ tests }) => {
  const chartRef = useRef(null);
  const [backgroundColor, setBackdroundColor] = useState('rgba(255, 255, 0, 0.2)');
  const scores = tests.map(test => test.score);

  const startValue = scores[0];
  const finishValue = scores[scores.length - 1];

  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) return;

    setBackdroundColor(getGradient(chart.ctx, chart.chartArea));
  }, []);

  const data = {
    labels: scores.map((x, i) => i),
    datasets: [
      {
        data: scores,
        fill: true,
        backgroundColor,
        borderColor: 'white',
        pointBackgroundColor: 'white',
        pointRadius: scores.map((x,i) => [0, scores.length-1].includes(i) ? 5 : 0),
        pointHoverRadius: 5,
        lineTension: 0.2,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Убрать легенду
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  };

  return (
    <div className="user-details-item  user-details-aim">
      <h3>Путь ученика</h3>
      <div className="user-details-aim-start">
        <h4>{startValue}</h4>
      </div>
      <div className="user-details-aim-end">
        <h4>{finishValue}</h4>
      </div>

      <Line ref={chartRef} data={data} options={options} />
    </div>
  );
};

const getGradient = (ctx, area) => {
  const gradient = ctx.createLinearGradient(0, area.height, 0, 0);

  gradient.addColorStop(0, 'rgba(255,255,255,0)');
  gradient.addColorStop(1, 'rgba(255,255,255,1)');

  return gradient;
};

export default AimChart;
