import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useApp } from '../../hooks/useApp';
import useGetApi from '../../hooks/useGetApi';
import Input from '../../ui/Input/Input';
import Page from '../../ui/Page/Page';
import Select from '../../ui/Select/Select';
import { avg } from '../../utils';
import DashboardList from './DashboardList';
import LastActivityItem from './LastActivityItem';
import './index.scss';

const Dashboard = () => {
  const { schoolClass, school, setStudentsNum, subjects } = useApp();

  const subjectsOptions = useMemo(() => {
    const options = [];
    for (const id in subjects) {
      if (subjects[id].test !== 'ent') continue;
      options.push({ value: subjects[id].slug, label: subjects[id].label })
    }
    return options;
  }, [subjects])

  const [loading, schoolInfo] = useGetApi(
    `schools/${school}${schoolClass?.value ? `?schoolClass=${schoolClass.value}` : ''}`,
    {
      students: [],
      lastActivities: [],
    }
  );
  const [search, setSearch] = useState('');
  const [subjectsSort, setSubjectsSort] = useState([]);
  const subjectsSortFlat = subjectsSort.map(x => x.value)

  useEffect(() => {
    setStudentsNum(schoolInfo.students.length);
  }, [schoolInfo]);

  const avgScoreAndActivity = useMemo(() => {
    let numWithoutTests = 0;
    let score = 0;
    let activity = 0;

    schoolInfo.students.forEach((stud) => {
      if (stud.testScores.length === 0) numWithoutTests++;

      score += avg(stud.testScores);
      activity += stud.weekActivity;
    });

    score = Math.round(score / (schoolInfo.students.length - numWithoutTests));
    activity = moment.duration(Math.round(activity / schoolInfo.students.length), 'seconds');

    return {
      score,
      activity,
    };
  }, [schoolInfo.students]);

  const filterStudents = () => {
    return schoolInfo.students.filter(
      (x) =>
        (x.name?.toLowerCase().includes(search.toLowerCase()) ||
        x?.user_id?.toString().includes(search)) &&
        (subjectsSort.length ? x?.subjects?.filter(x => subjectsSortFlat.includes(x))?.length === subjectsSortFlat.length : true)
    );
  };

  return (
    <Page name="dashboard" className="dashboard">
      <div className="dashboard-wrapper">
        <div className="dashboard-main">
          <div className="dashboard-main__item main-block">
            <div className="dashboard-main__item-title">
              <h2>Балл учеников</h2>
              <h4>Среднее значение</h4>
            </div>
            <div className="dashboard-main__item-value">
              {avgScoreAndActivity.score || '--'} <span>баллов</span>
            </div>
            <DecorLine className="dashboard-main__item-decor" />
          </div>
          <div className="dashboard-main__item main-block">
            <div className="dashboard-main__item-title">
              <h2>Время активности</h2>
              <h4>Среднее значение за неделю</h4>
            </div>
            <div className="dashboard-main__item-value">
              {Math.round(avgScoreAndActivity.activity.asMinutes()) || '--'} <span>мин.</span>
            </div>
            <DecorTriangle className="dashboard-main__item-decor" />
          </div>
          <div className="dashboard-main-list">
            <div className="dashboard-main-list-top">
              <h2 className="title">Ученики</h2>
              <Input
                value={search}
                name="search"
                label="Поиск"
                onChange={(e) => setSearch(e.target.value)}
              />
              <Select
                className="dashboard-main-list-subjects"
                isMulti
                placeholder='Предметы'
                value={subjectsSort}
                onChange={v => setSubjectsSort(v)}
                options={subjectsOptions}
                styles={{
                  control: (_) => ({
                    ..._,
                    borderRadius: 50,
                    minHeight: 42
                  }),
                }}
              />
            </div>
            <DashboardList students={filterStudents()} loading={loading} search={search} />
          </div>
        </div>
        <div className="dashboard-activities ">
          <h2 className="title">Последние действия</h2>
          <div className="dashboard-activities-list main-scroll">
            {schoolInfo.lastActivities.map((act, i) => (
              <LastActivityItem key={i} activity={act} />
            ))}
          </div>
        </div>
      </div>
    </Page>
  );
};

const DecorLine = ({ className }) => (
  <svg
    className={className}
    width="139"
    height="50"
    viewBox="0 0 139 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.08978 15.269C23.666 0.974642 34.7618 2.79503 36.3771 20.7302C38.0008 38.4212 49.0965 40.2416 69.6644 26.1913C90.2014 12.1359 101.297 13.9563 102.952 31.6525C104.682 47.0295 115.777 48.8499 136.239 37.1136"
      stroke="#4C75F2"
      strokeWidth="10"
    />
  </svg>
);

const DecorTriangle = ({ className }) => (
  <svg
    className={className}
    width="98"
    height="64"
    viewBox="0 0 98 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.4492 0.407227L97.4626 28.4658L44.6565 63.8115L40.4492 0.407227Z"
      fill="url(#paint0_linear_3152_2653)"
    />
    <path
      d="M0.394531 25.5052L29.7246 5.78711L32.1359 41.0467L0.394531 25.5052Z"
      fill="url(#paint1_linear_3152_2653)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3152_2653"
        x1="68.9559"
        y1="-88.579"
        x2="68.9559"
        y2="63.8116"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#EFBD59" />
        <stop offset="1" stopColor="#F1A518" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3152_2653"
        x1="16.2652"
        y1="-43.6988"
        x2="16.2652"
        y2="41.0467"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#EFBD59" />
        <stop offset="1" stopColor="#F1A518" />
      </linearGradient>
    </defs>
  </svg>
);

export default Dashboard;
