/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import * as ReactSelect from 'react-select';

const Select = ({ options, value, placeholder, onChange, className = '', disabled, styles, isMulti }) => {
  return (
    <ReactSelect.default
      options={options}
      className={className}
      placeholder={placeholder}
      isDisabled={disabled}
      value={value}
      onChange={onChange}
      styles={styles}
      isMulti={isMulti}
      theme={(theme) => ({
        ...theme,
        borderRadius: 8,
        colors: {
          ...theme.colors,
          primary25: '#DAE3FF',
          primary: '#4c75f2',
        },
      })}
    />
  );
};

export default Select;
