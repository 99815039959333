import React from 'react';

const IconChem = () => {
  return (
    <svg
      className="icon icon-chem"
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5001 21.5L10.3868 20.5064C9.83811 20.569 9.44398 21.0645 9.50653 21.6133C9.56907 22.1619 10.0645 22.5561 10.6132 22.4936V22.4936L10.6202 22.4928L10.646 22.49C10.6692 22.4875 10.7045 22.4838 10.7506 22.4791C10.8428 22.4697 10.9783 22.4566 11.1481 22.442C11.488 22.4128 11.963 22.3777 12.5012 22.3543C13.599 22.3066 14.8802 22.3119 15.8203 22.4837C16.8833 22.678 17.6298 23.0967 18.5034 23.6006C18.523 23.6119 18.5426 23.6233 18.5624 23.6347C19.4208 24.1302 20.4119 24.7022 21.804 24.9806C24.4562 25.511 26.0847 25.3552 28.6415 24.99C29.8239 24.8211 31.2601 24.4267 32.3685 24.0884C32.9298 23.9171 33.4202 23.7559 33.7707 23.6375C33.9461 23.5783 34.0868 23.5296 34.1842 23.4955C34.233 23.4785 34.2709 23.4651 34.2969 23.4558L34.327 23.4451L34.3351 23.4422L34.3374 23.4414C34.3374 23.4414 34.3384 23.441 34.0001 22.5001L34.3384 23.441C34.8582 23.2542 35.128 22.6814 34.9411 22.1617C34.7542 21.642 34.1815 21.3722 33.6618 21.559M33.6618 21.559L33.6541 21.5617L33.6281 21.571C33.6048 21.5792 33.5698 21.5916 33.5241 21.6076C33.4326 21.6396 33.2986 21.6859 33.1306 21.7427C32.7942 21.8564 32.3229 22.0112 31.7846 22.1755C30.694 22.5084 29.3803 22.8641 28.3587 23.0101C25.8897 23.3628 24.5222 23.4846 22.1962 23.0194C21.1311 22.8064 20.3841 22.3766 19.5027 21.8682L19.5012 21.8673C18.6217 21.36 17.6131 20.7782 16.1798 20.5163C15.0071 20.302 13.5382 20.3074 12.4144 20.3562C11.8417 20.3811 11.3378 20.4183 10.9766 20.4494C10.7958 20.465 10.6502 20.479 10.5489 20.4893C10.4983 20.4944 10.4587 20.4986 10.4313 20.5015L10.3995 20.505L10.3907 20.506L10.3868 20.5064C10.3868 20.5064 10.3868 20.5064 10.5001 21.5"
        fill="#7E5CE7"
      />
      <path
        d="M17.0001 27.0001C17.0001 27.5523 16.5524 28.0001 16.0001 28.0001C15.4478 28.0001 15.0001 27.5523 15.0001 27.0001C15.0001 26.4478 15.4478 26.0001 16.0001 26.0001C16.5524 26.0001 17.0001 26.4478 17.0001 27.0001Z"
        fill="#7E5CE7"
      />
      <path
        d="M22.0001 33.0001C22.0001 33.5523 21.5524 34.0001 21.0001 34.0001C20.4478 34.0001 20.0001 33.5523 20.0001 33.0001C20.0001 32.4478 20.4478 32.0001 21.0001 32.0001C21.5524 32.0001 22.0001 32.4478 22.0001 33.0001Z"
        fill="#7E5CE7"
      />
      <path
        d="M30.0001 29.0001C30.0001 29.5523 29.5524 30.0001 29.0001 30.0001C28.4478 30.0001 28.0001 29.5523 28.0001 29.0001C28.0001 28.4478 28.4478 28.0001 29.0001 28.0001C29.5524 28.0001 30.0001 28.4478 30.0001 29.0001Z"
        fill="#7E5CE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 5C16 4.44772 16.4477 4 17 4H27C27.5523 4 28 4.44772 28 5C28 5.55228 27.5523 6 27 6H26.5V13.2392C32.0238 15.1132 36 20.3411 36 26.5C36 34.232 29.732 40.5 22 40.5C19.6416 40.5 17.4167 39.9161 15.4648 38.884C14.9766 38.6259 14.79 38.0208 15.0482 37.5326C15.3063 37.0443 15.9114 36.8578 16.3996 37.116C18.0704 37.9994 19.9753 38.5 22 38.5C28.6274 38.5 34 33.1274 34 26.5C34 20.9933 30.29 16.3506 25.2314 14.9398C24.799 14.8193 24.5 14.4254 24.5 13.9766V6H19.5V13.9766C19.5 14.4254 19.201 14.8193 18.7686 14.9398C13.71 16.3506 10 20.9933 10 26.5C10 29.1476 10.8562 31.5922 12.3072 33.576C12.6332 34.0218 12.5361 34.6475 12.0903 34.9735C11.6446 35.2996 11.0189 35.2025 10.6928 34.7567C8.99977 32.4418 8 29.5862 8 26.5C8 20.3411 11.9762 15.1132 17.5 13.2392V6H17C16.4477 6 16 5.55228 16 5Z"
        fill="#3E209B"
      />
      <defs>
        <linearGradient
          id="SVGgold"
          x1="22.9799"
          y1="3"
          x2="22.9799"
          y2="41"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FCEA59" />
          <stop offset="1" stopColor="#FFB60A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconChem;
