import IconBio from './IconBio'
import IconChem from './IconChem'
import IconEng from './IconEng'
import IconGeo from './IconGeo'
import IconHist from './IconHist'
import IconHistoryKaz from './IconHistoryKaz'
import IconInfo from './IconInfo'
import IconKaz from './IconKaz'
import IconKazLit from './IconKazLit'
import IconLaw from './IconLaw'
import IconLiter from './IconLiter'
import IconMath from './IconMath'
import IconMathLit from './IconMathLit'
import IconPhys from './IconPhys'
import IconRus from './IconRus'
import IconRusLit from './IconRusLit'

export const getSubjectIcon = (slug) => {
    switch (slug) {
        case 'bio': return <IconBio />
        case 'chem': return <IconChem />
        case 'eng': return <IconEng />
        case 'geo': return <IconGeo />
        case 'hist': return <IconHist />
        case 'hist-kz': return <IconHistoryKaz />
        case 'info': return <IconInfo />
        case 'law': return <IconLaw />
        case 'read-lit': return <IconLiter />
        case 'math2': return <IconMath />
        case 'math-lit': return <IconMathLit />
        case 'phys': return <IconPhys />
        case 'kaz': return <IconKaz />
        case 'kaz-lit': return <IconKazLit />
        case 'rus': return <IconRus />
        case 'rus-lit': return <IconRusLit />
    
        default: return null
    }
}