import { createContext, useState } from 'react';
import useGetApi from '../hooks/useGetApi';

export const AppContext = createContext({
  subjects: [],
  getSubjectById: () => {},
  changePage: () => {},
  schoolClass: null,
  changeClass: () => {},
  page: null,
  school: '',
  studentsNum: 0,
  setStudentsNum: (v) => {}
});

export const AppProvider = ({ children }) => {
  const [loading, subjects] = useGetApi('subjects/themes', null, {
    success: (data) => {
      const mappingSubj = {}
      data.forEach(subj => {
        const mappingTheme = {}
        subj.themes.forEach(theme => mappingTheme[theme.id] = theme)
        subj.themes = mappingTheme

        mappingSubj[subj.id] = subj
        mappingSubj[subj.slug] = subj
      })
      return mappingSubj
    }
  });

  const [school, setSchool] = useState(window.location.hostname.split('.')[0]);
  const [studentsNum, setStudentsNum] = useState(0);

  const [page, setPage] = useState(null);
  const [schoolClass, setSchoolClass] = useState(null);

  const getSubjectById = (id) => subjects[id] || null;

  const changePage = (x) => setPage(x)

  const changeClass = (x) => setSchoolClass(x)

  if (loading || !subjects) return null;

  return (
    <AppContext.Provider
      value={{
        subjects,
        getSubjectById,
        changePage,
        page,
        school,
        studentsNum,
        setStudentsNum,

        schoolClass,
        changeClass,
      }}>
      {children}
    </AppContext.Provider>
  );
};
