import { Store } from 'react-notifications-component';

const useNotify = () => {
  const notify = (title = '', message = '', type = 'default' ) => {
    Store.addNotification({
      title,
      message,
      type,
      insert: 'top',
      container: 'top-right',
      dismiss: {
        duration: 2000,
        onScreen: true,
        showIcon: true,
      },
    });
  };
  return { notify }
};

export default useNotify;