import React from 'react';

import logo from '../../assets/logo.svg';

import { useApp } from '../../hooks/useApp';
import { useAuth } from '../../hooks/useAuth';
import Icon from '../../icons/FlatIcon';
import IconButton from '../../ui/IconButton/IconButton';
import Select from '../../ui/Select/Select';
import './index.scss';
import { pluralize } from '../../utils';
import useGetApi from '../../hooks/useGetApi';

const Header = () => {
  const { page, changeClass, schoolClass, school, studentsNum } = useApp();
  const { logout } = useAuth();

  const [loading, classes] = useGetApi(`schools/${school}/classes`, [])

  return (
    <header className="header">
      <hr />
      <div className="header-wrapper">
        <div className="header-logo">
          <img src={logo} alt="Jaryq School" />
          <div>
            <h2>Binom School</h2>
            <h4>{pluralize(studentsNum, ['ученик', 'ученика', 'учеников'], 1)}</h4>
          </div>
        </div>
        <hr className="spacer" />
        {page === 'dashboard' && (
          <Select
            disabled={loading}
            className="header-select"
            options={classes}
            placeholder='Выберите класс'
            value={schoolClass}
            onChange={(v) => {
              changeClass(v);
            }}
          />
        )}
        {page === 'user-details' && (
          <a href="/dashboard" className="header-back">
            <Icon slug="fi-rr-arrow-small-left" />
            <h3>Вернуться назад</h3>
          </a>
        )}

        <hr />
        <IconButton onClick={logout} slug="fi-rr-exit" />
      </div>
      <hr />
    </header>
  );
};

export default Header;
