import React from 'react';

const IconBio = () => {
  return (
    <svg
      className="icon icon-bio"
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8273 19.6095H21.3114H22.6951H24.1792C25.7859 19.6095 25.9568 19.6413 26.1247 19.9712C26.3911 20.4948 26.0868 21.0092 25.4755 21.0689L25.1164 21.1039V22.8969V24.6897L28.6909 29.2304C30.6569 31.7278 32.3532 33.9148 32.4606 34.0906C32.9171 34.8379 33.1094 35.9493 32.9381 36.8506C32.7537 37.8207 32.0477 38.8779 31.227 39.4126C31.0302 39.5409 30.6356 39.7254 30.3501 39.8229C29.8334 39.9992 29.8192 40 27.33 40H24.829L24.6268 39.7945C24.3421 39.5051 24.3421 39.0887 24.6268 38.7993L24.829 38.5938L26.9271 38.5924C28.081 38.5916 29.213 38.5633 29.4426 38.5295C30.6376 38.3537 31.4672 37.4348 31.5343 36.2129C31.585 35.2892 31.5707 35.2657 28.8848 31.8514L26.5004 28.8204H24.5977H22.6951H21.3114H19.4116H17.5117L15.1255 31.8486C12.4358 35.262 12.4213 35.2859 12.4721 36.2129C12.5389 37.4293 13.3743 38.3545 14.5638 38.5295C14.7934 38.5633 15.9255 38.5916 17.0794 38.5924L19.1775 38.5938L19.3797 38.7993C19.5382 38.9605 19.5819 39.0678 19.5819 39.2969C19.5819 39.526 19.5382 39.6333 19.3797 39.7945L19.1775 40H16.6765C14.1873 40 14.1731 39.9992 13.6564 39.8229C12.9846 39.5937 12.5168 39.2842 12.0253 38.7436C11.184 37.8184 10.8275 36.5112 11.0793 35.275C11.2553 34.4107 11.4635 34.1133 15.2535 29.3126L18.8893 24.7071L18.8897 22.9055L18.8901 21.1039L18.531 21.0689C18.239 21.0403 18.1356 20.9924 17.9775 20.8127C17.7592 20.5644 17.7265 20.2765 17.8818 19.9712C18.0497 19.6413 18.2206 19.6095 19.8273 19.6095ZM19.9942 27.3438H21.3114H22.6951H24.0123H25.3295L24.6193 26.4488C24.2288 25.9566 23.854 25.4436 23.7864 25.3089C23.6774 25.0915 23.6636 24.8414 23.6636 23.0751V21.086H23.1793H22.6951H21.3114H20.8272H20.3429V23.0751C20.3429 24.8414 20.3291 25.0915 20.2201 25.3089C20.1525 25.4436 19.7777 25.9566 19.3871 26.4488L18.677 27.3438H19.9942ZM22.0195 40C21.3732 40 21.0611 39.2592 21.5136 38.7993C21.9165 38.3899 22.6089 38.5954 22.7195 39.1573C22.8098 39.6165 22.4912 40 22.0195 40Z"
        fill="#3E209B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3248 4.15016C22.89 4.66934 21.5421 6.30396 21.3347 8.98869C21.292 9.54289 21.2821 9.57221 21.1869 9.42652C21.0455 9.21003 20.3184 8.63446 19.8453 8.36453C19.3218 8.0657 18.4177 7.75893 17.6293 7.61247C16.866 7.47065 14.7921 7.44731 14.1512 7.57323C13.3455 7.73165 13.2482 8.01613 13.2571 10.1877C13.2615 11.2499 13.2906 11.6222 13.4159 12.2178C14.0509 15.2377 15.847 16.7557 19.1709 17.0815C19.6644 17.1299 20.3001 17.1452 20.6697 17.1176L21.3114 17.0697V18.3396V19.6095H22.6951V15.8829V12.1564H23.5853C24.6309 12.1564 25.4475 12.0265 26.2328 11.7352C28.3367 10.9548 29.3365 9.17677 29.3365 6.21537C29.3365 4.52879 29.2129 4.20669 28.514 4.07197C27.868 3.94745 26.0684 3.99153 25.3248 4.15016ZM27.9005 5.47674H27.5518C27.36 5.47674 27.1617 5.46556 27.111 5.45192C27.0603 5.43828 26.7312 5.45093 26.3794 5.48011C24.0783 5.67066 22.9007 6.89029 22.7847 9.20328L22.7477 9.94156L23.7418 8.94524C24.3208 8.36488 24.8132 7.92923 24.9212 7.9018C25.4381 7.77025 25.9334 8.33036 25.7714 8.86318C25.7439 8.95353 25.3596 9.4029 24.9174 9.86182L24.1133 10.6961L24.5198 10.6466C26.272 10.4335 27.2877 9.60287 27.7181 8.03104C27.788 7.77573 27.8435 7.21696 27.8654 6.549L27.9005 5.47674ZM20.2338 10.6022C19.6111 9.73997 18.4218 9.1741 16.8607 8.9972C16.22 8.92456 15.4273 8.91437 14.9664 8.97273C14.7732 8.9972 14.7378 9.03432 14.6971 9.25419C14.6255 9.64203 14.7158 11.3547 14.8384 11.9346C15.3391 14.303 16.7637 15.4296 19.5145 15.6326L20.1699 15.6811L19.9099 15.448C19.7668 15.3198 19.2183 14.8353 18.6909 14.3712C18.1635 13.9072 17.6813 13.4435 17.6192 13.3409C17.2041 12.6539 18.0284 11.8921 18.6559 12.3828C18.7657 12.4686 19.338 12.967 19.9278 13.4902C20.5176 14.0135 21.0592 14.4889 21.1313 14.5467C21.2591 14.6489 21.2617 14.6291 21.2262 13.8084C21.1688 12.4823 20.8517 11.4576 20.2338 10.6022Z"
        fill="#7E5CE7"
      />
      <path d="M21.3114 24.2149V27.3438H22.6951V24.2149V21.086H21.3114V24.2149Z" fill="#7E5CE7" />
      <path
        d="M21.3114 28.8204V29.9305V31.0407L19.932 32.602C18.3535 34.3886 18.3366 34.4105 18.3366 34.6686C18.3366 35.2686 18.9395 35.6238 19.4329 35.3146C19.5339 35.2513 19.9901 34.7718 20.4467 34.2491L21.2768 33.2986L21.298 33.5499C21.3493 34.1595 22.0843 34.4141 22.5186 33.9727C22.685 33.8035 22.6921 33.7591 22.7105 32.7718L22.7296 31.7466L23.5031 32.7979C23.9285 33.376 24.3488 33.9158 24.437 33.9974C24.6657 34.2086 24.9343 34.2549 25.2221 34.1327C25.5075 34.0114 25.707 33.6231 25.6355 33.3279C25.611 33.2271 24.9394 32.2626 24.143 31.1845C23.0417 29.6937 22.6951 29.1762 22.6951 29.0224V28.8204H21.3114Z"
        fill="#7E5CE7"
      />
      <defs>
        <linearGradient
          id="SVGgold"
          x1="22.9799"
          y1="3"
          x2="22.9799"
          y2="41"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FCEA59" />
          <stop offset="1" stopColor="#FFB60A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconBio;
