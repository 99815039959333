import { useState } from 'react';
import Icon from '../icons/FlatIcon';
import { $class } from '../utils';

export const usePagination = ({ rowsPerPage = 8, length = 0 }) => {
  const [page, setPage] = useState(0);

  const nextPage = () => {
    if (isMaxPage) return;
    setPage((x) => x + 1);
  };

  const prevPage = () => {
    if (isMinPage) return;
    setPage((x) => x - 1);
  };

  const maxPage = rowsPerPage * (page + 1);
  const isMaxPage = maxPage >= length;

  const minPage = rowsPerPage * page;
  const isMinPage = minPage === 0;

  const PaginationNav = () => (
    <div className="pagination">
      <Icon
        onClick={prevPage}
        className={$class(['pagination__item', ['disabled', isMinPage]])}
        slug="fi-rr-arrow-small-left"
      />
      <h4>
        {length === 0 ? 0 : minPage + 1} - {Math.min(maxPage, length)} из {length}
      </h4>
      <Icon
        onClick={nextPage}
        className={$class(['pagination__item', ['disabled', isMaxPage]])}
        slug="fi-rr-arrow-small-right"
      />
    </div>
  );

  return { PaginationNav, minPage, maxPage, page };
};
