import { useCallback, useState } from 'react';

import { API } from '../config';
import { useAuth } from './useAuth';

export const useApi = (params = {}) => {
  const { initLoading } = {
    initLoading: params.initLoading || false,
  };

  const [loading, setLoading] = useState(initLoading);
  const { token, logout } = useAuth();

  const fetcher = useCallback(async (url, props) => {
    props = props || {};
    props.method = props.method || 'GET';
    props.body = props.body || null;
    props.headers = props.headers || {};
    props.type = props.type || 'json';
    props.protect = props.protect || true;
    props.success = props.success || undefined;
    props.error = props.error || (() => {});

    let { method, body, headers, type, protect, success, error } = props;
    setLoading(true);

    if (body && type === 'json') {
      body = JSON.stringify(body);
      headers['Content-Type'] = 'application/json';
    }

    if (protect && token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    try {
      const response = await fetch(`${API}${url}`, { method, body, headers });
      const data = await response.json();

      if (response.status === 401) {
        console.error(data);
        logout()
        error(data);
        return setLoading(false);
      }

      if (response.status === 403) {
        console.error(data);
        error(data);
        return setLoading(false);
      }

      if (!response.ok) {
        console.error(data);
        error(data);
        return setLoading(false);
      }
        
      setLoading(false);
      if (success) return success(data);
      return data
    } catch (e) {
      console.warn('useApi Error', e);
      setLoading(false);
    }
  }, []);

  return [loading, fetcher];
};
