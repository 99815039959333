import moment from 'moment';
import React from 'react';
import { useApp } from '../../hooks/useApp.js';
import { $class } from '../../utils';
import IconButton from '../../ui/IconButton/IconButton';

const LastActivityItem = ({ activity }) => {
  const { getSubjectById } = useApp();
  const type = 'finish_data' in activity ? 'test' : 'theme';

  const subject = getSubjectById(activity.subjectId) || {
    themes: {},
  };

  const testGotoHandler = () => {
    window.open(`https://app.jaryq-academy.kz/test/${activity.id}`, '_blank')
  }

  return (
    <div
      className={$class([
        'dashboard-activities__item',
        ['dashboard-activities__item--test', type === 'test'],
        ['dashboard-activities__item--theme', type === 'theme'],
      ])}
      style={{ ...(type === 'theme' ? { background: subject.color } : {}) }}>

      {type === 'test' && <IconButton onClick={testGotoHandler} className='test-goto' slug='fi-rr-arrow-up-right-from-square' />}

      {type === 'test' ? (
        <h2>Тест ЕНТ</h2>
      ) : (
        <>
          <h2>{subject.label}</h2>
          <h4>{subject.themes[activity.themeId]?.label}</h4>
        </>
      )}

      <div className="dashboard-activities__item-bottom">
        <h4>{activity.meta.name}</h4>
        <h5>{activity.meta.class || '--'}</h5>
      </div>
      <h5 className="dashboard-activities__item-time">{moment(activity.datetime).fromNow()}</h5>
    </div>
  );
};

export default LastActivityItem;
