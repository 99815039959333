import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import './index.scss';

import background from '../../assets/authBackground.svg';
import { useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import Icon from '../../icons/FlatIcon';
import Input from '../../ui/Input/Input';
import { $class, emailRegex } from '../../utils';

const checkValid = {
  email: (v) => v !== '' && emailRegex(v),
  password: (v) => v !== '' && v.length >= 6,
};

const Auth = () => {
  const { login } = useAuth();
  const [form, setForm] = useState({
    email: {
      value: '',
      isValid: true,
    },
    password: {
      value: '',
      isValid: true,
    },
  });
  const [loading, api] = useApi();
  const { notify } = useNotify();

  const formChangeHandler = (e) => {
    let { name, value } = e.target;
    if (name === 'email') value = value.trim();
    setForm({ ...form, [name]: { value: value, isValid: checkValid[name](value) } });
  };

  const clickHandler = () => {
    const { email, password } = form;

    if (!email.isValid || !password.isValid) return;

    api('auth/login', {
      method: 'POST',
      body: {
        email: email.value,
        password: password.value,
      },
      success: ({ token, role }) => {
        if (!['superadmin', 'school-admin'].includes(role.slug)) {
          return notify('Ошибка входа', 'Нет доступа', 'danger');
        }

        notify('Добро пожаловать!', 'Вы успешно вошли', 'success');
        login(token)
      },
      error: (e) => {
        console.warn(e);
        if (e.code === 400) {
          notify('Ошибка входа', e.msg, 'danger');
        } else notify('Ошибка входа', 'Попробуйте еще раз', 'danger');
      },
    });
  };

  return (
    <div className="auth" style={{ backgroundImage: `url('${background}')` }}>
      <div className="auth-wrapper">
        <img
          className="auth-logo"
          src={require('../../assets/logo-full.png')}
          alt="Jaryq Schools"
        />
        <div className="auth-form">
          <h1>Авторизация</h1>
          <Input
            invalid={!form.email.isValid}
            value={form.email.value}
            onChange={formChangeHandler}
            name="email"
            label="Email"
          />
          <Input
            invalid={!form.password.isValid}
            value={form.password.value}
            onChange={formChangeHandler}
            type="password"
            name="password"
            label="Пароль"
          />
          <button
            className={$class([
              'auth-form-submit',
              ['disabled', !form.email.isValid || !form.password.isValid],
            ])}
            onClick={clickHandler}>
            {loading ? <Icon loading slug="fi-rr-spinner" /> : 'Войти'}
          </button>
        </div>
      </div>
      <div className="auth-credits">
        <h4>Jaryq Academy, 2023</h4>
      </div>
    </div>
  );
};

export default Auth;
