import React, { useMemo, useState } from 'react';
import { usePagination } from '../../hooks/usePagination';
import { getSubjectIcon } from '../../icons';
import Icon from '../../icons/FlatIcon';
import { $class } from '../../utils';

const DashboardList = ({ students, loading, search = '' }) => {
  const { minPage, maxPage, page, PaginationNav } = usePagination({ length: students.length });
  const [sorts, setSorts] = useState({
    tests: 0,
    rating: 0,
  });

  const onChangeSort = (slug) => {
    let newValue = sorts[slug];
    if (newValue === 0) newValue = 1;
    else if (newValue === 1) newValue = -1;
    else newValue = 0;
    setSorts({ ...sorts, [slug]: newValue });
  };

  const sortedStudents = useMemo(() => {
    const studs = [...students];

    studs.sort(
      (a, b) =>
        sorts.tests * ((b.testScores?.length || 0) - (a.testScores?.length || 0)) ||
        sorts.rating * (b.exp - a.exp)
    );
    return studs.slice(minPage, maxPage);
  }, [maxPage, minPage, sorts.rating, sorts.tests, students]);

  return (
    <div className="dashboard-main-list-wrap main-block">
      <div className="dashboard-main-list-head">
        <h4>Имя</h4>
        <h4>Класс</h4>
        <h4 className="has-sort">
          Тестов{' '}
          <Icon
            className={$class([['active', sorts.tests !== 0]])}
            slug={`fi-rr-sort-amount-down${sorts.tests === 1 ? '-alt' : ''}`}
            onClick={() => onChangeSort('tests')}
          />
        </h4>
        <h4 className="has-sort">
          Рейтинг{' '}
          <Icon
            className={$class([['active', sorts.rating !== 0]])}
            slug={`fi-rr-sort-amount-down${sorts.rating === 1 ? '-alt' : ''}`}
            onClick={() => onChangeSort('rating')}
          />
        </h4>
        <h4>Предметы</h4>
      </div>
      {!loading ? (
        sortedStudents.map((user) => <StudentItem key={user.user_id} user={user} />)
      ) : (
        <MockupLoading />
      )}
      {!sortedStudents.length && !loading && (
        <div className="dashboard-main-list-empty">
          <img src={require('../../assets/search.png')} alt="" />
          <h4>Учеников не найдено...</h4>
        </div>
      )}
      <PaginationNav />
    </div>
  );
};

const StudentItem = ({ user = {}, className }) => (
  <div className={$class(['dashboard-main-list__item', className])}>
    <a href={`./students/${user.user_id}`}>
      <h4>
        {user.name} {user.surname}
      </h4>
    </a>
    <h4>{user.class || '--'}</h4>
    <h4>{user?.testScores?.length || 0}</h4>
    <h4>
      {user.exp}
      <svg
        width="16"
        height="22"
        viewBox="0 0 16 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 0.5L10.1607 8.16405L16 11L10.1607 13.836L8 21.5L5.83927 13.836L0 11L5.83927 8.16405L8 0.5Z"
          fill="#9A9A9A"
        />
      </svg>
    </h4>
    <h4>
      {user?.subjects?.map((x) =>
        ['math-lit', 'read-lit', 'hist-kz'].includes(x) ? null : (
          <span key={x.toString()} className="tooltip">
            {getSubjectIcon(x)}
            <p className="tooltip-msg">{x}</p>
          </span>
        )
      )}
    </h4>
  </div>
);

const MockupLoading = () => {
  return Array(6)
    .fill()
    .map((x, i) => <StudentItem key={i} className="skeleton" />);
};

export default DashboardList;
