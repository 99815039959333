import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import useGetApi from '../../hooks/useGetApi';
import Page from '../../ui/Page/Page';
import './index.scss';
import AimChart from './AimChart';
import TestsList from './TestsList';
import moment from 'moment';
import { formatPhone } from '../../utils';
import Activity from './Activity';
import Statistic from './Statistic';

const UserDetails = () => {
  const { userId } = useParams();
  const [loading, user] = useGetApi(`user/${userId}?include=tests`, { profile: {}, role: {}, tests: [] });

  const formattedTests = useMemo(() => {
    return user.tests.map((test) => {
      const { id, createdDate, finish_data } = test;
      const { score, finishDate } = JSON.parse(finish_data);

      return {
        id,
        percent: Math.round((score / 140) * 100),
        duration: moment.duration(new Date(finishDate) - new Date(createdDate), 'milliseconds'),
        score,
        finishDate: moment(finishDate)
      };
    });
  }, [user.tests]);

  return (
    <Page name="user-details">
      <div className="user-details-wrapper">
        <div className="user-details-item user-details-card">
          <div className="user-details-card-info">
            <img
              src={
                user.profile.avatar || 'https://jaryq-academy.kz/uploads/icons/avatar-default.png'
              }
              alt=""
            />
            <div>
              <h2>
                {user.profile.name} {user.profile.surname}
              </h2>
              <h4>{user.profile.class || '--'}</h4>
            </div>
          </div>
          <p>{user.profile.email}</p>
          <p>{formatPhone(user.profile.phone)}</p>
        </div>

        <AimChart tests={formattedTests} />

        <Activity />

        <TestsList tests={formattedTests} />

        <Statistic user={user} />

      </div>
    </Page>
  );
};

export default UserDetails;
