import React, { useEffect } from 'react';
import { $class } from '../../utils';
import { useApp } from '../../hooks/useApp';

const Page = ({ name = 'default', className, children }) => {
    const { changePage } = useApp()

    useEffect(() => {
        changePage(name)
    }, []);

    return (
        <div className={$class(['page', className])}>
            {children}
        </div>
    );
}

export default Page;
