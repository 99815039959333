import React from 'react';

const IconEng = () => {
  return (
    <svg
      className="icon icon-eng"
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5211 5.54605C14.9405 6.43751 11.79 8.37289 9.43288 10.9989L18.5211 17.6823V5.54605Z"
        fill="#3E209B"
      />
      <path
        d="M36.6007 11.0364C34.2585 8.41203 31.126 6.47235 27.5634 5.56731V17.6823L36.6007 11.0364Z"
        fill="#3E209B"
      />
      <path
        d="M25.8732 5.22174C24.9376 5.07582 23.9779 5 23 5C22.0515 5 21.1201 5.07133 20.2113 5.20876V19.7411H5.22242C5.07602 20.6398 5 21.5613 5 22.5C5 23.4387 5.07601 24.3601 5.22238 25.2587H20.2113V39.7912C21.1201 39.9287 22.0515 40 23 40C23.9779 40 24.9376 39.9242 25.8732 39.7783V25.2587H40.7776C40.924 24.3601 41 23.4387 41 22.5C41 21.5613 40.924 20.6398 40.7776 19.7411H25.8732V5.22174Z"
        fill="#7E5CE7"
      />
      <path
        d="M8.35083 32.6713L16.6962 26.9768H12.9438L7.20226 30.8946C7.54912 31.5103 7.93294 32.1034 8.35083 32.6713Z"
        fill="#7E5CE7"
      />
      <path
        d="M6.68937 29.9117C8.25926 28.8866 11.1503 26.9881 11.0845 26.9881H5.59744C5.87325 28.0016 6.24025 28.9791 6.68937 29.9117Z"
        fill="#3E209B"
      />
      <path
        d="M27.5634 39.4327C31.1486 38.5219 34.2981 36.5634 36.645 33.9137L27.5634 27.2352V39.4327Z"
        fill="#3E209B"
      />
      <path
        d="M37.6324 32.6941C38.0512 32.1269 38.4359 31.5344 38.7838 30.9193L33.006 26.9768H29.2536L37.6324 32.6941Z"
        fill="#7E5CE7"
      />
      <path
        d="M39.3109 29.9112C39.7599 28.9787 40.1268 28.0014 40.4026 26.9881H34.9166C34.8509 26.9881 37.7408 28.8858 39.3109 29.9112Z"
        fill="#3E209B"
      />
      <path
        d="M38.7818 14.0771C38.4338 13.4622 38.0489 12.8697 37.6299 12.3026L29.1691 18.0759H32.9215L38.7818 14.0771Z"
        fill="#7E5CE7"
      />
      <path
        d="M39.3409 15.1514C37.7779 16.1719 34.8504 18.094 34.9166 18.094H40.4247C40.1526 17.0741 39.7883 16.0902 39.3409 15.1514Z"
        fill="#3E209B"
      />
      <path
        d="M8.32505 12.3638C7.90856 12.9327 7.52619 13.5269 7.18085 14.1435L12.9438 18.0759H16.6962L8.32505 12.3638Z"
        fill="#7E5CE7"
      />
      <path
        d="M6.6795 15.1088C6.22277 16.0604 5.85151 17.0586 5.5753 18.094H11.169C11.2362 18.094 8.22459 16.1174 6.6795 15.1088Z"
        fill="#3E209B"
      />
      <path
        d="M18.5211 39.4539V27.2352L9.3886 33.9516C11.7414 36.5556 14.9602 38.5674 18.5211 39.4539Z"
        fill="#3E209B"
      />
    </svg>
  );
};

export default IconEng;
