import React, { useMemo } from 'react';
import { usePagination } from '../../hooks/usePagination';
import { getPercentColor, pluralize } from '../../utils';
import moment from 'moment';

const TestsList = ({ tests }) => {
  const { PaginationNav, minPage, maxPage } = usePagination({ length: tests.length });

  const getTestDuration = (duration) => {
    const res = [];
    if (duration.hours() !== 0) res.push(pluralize(duration.hours(), ['час', 'часа', 'часов'], true))
    if (duration.minutes() !== 0) res.push(pluralize(duration.minutes(), ['минута', 'минуты', 'минут'], true))
    if (duration.seconds() && res.length === 0) res.push(pluralize(duration.seconds(), ['секунда', 'секунды', 'секунд'], true))
    return res.join(' ')
  }

  return (
    <div className="user-details-list">
      <h2 className="title">Тесты</h2>
      <div className="user-details-list-wrap main-block">
        {tests.slice(minPage, maxPage).map((test) => (
          <a href={`https://app.jaryq-academy.kz/test/${test.id}`} target='_blank' className="user-details-list__item" key={test.id} rel="noreferrer">
            <PercentCircle value={test.percent} />
            <h4 className='user-details-list__item-block'>{pluralize(test.score, ['балл', 'балла', 'баллов'], 1)}</h4>
            <h4 className='user-details-list__item-block'>{getTestDuration(test.duration)}</h4>
            <h5 className='user-details-list__item-date'>{test.finishDate.fromNow()}</h5>
          </a>
        ))}

        <PaginationNav />
      </div>
    </div>
  );
};

const PercentCircle = ({ value }) => (
  <div className="percent-circle">
    <svg width={48} height={48} fill="none" viewBox="0 0 36 36" className="circular">
      <path
        className="circular-bg"
        d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
        stroke={getPercentColor(value)}
        opacity="0.2"
        strokeWidth={3}
      />
      <path
        className="circular-value"
        strokeDasharray={`${value} 100`}
        d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
        stroke={getPercentColor(value)}
        strokeWidth={3}
        style={{ transition: '0.6s ease' }}
      />
    </svg>
    <h5>{value}%</h5>
  </div>
);

export default TestsList;
